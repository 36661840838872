<template>
  <div class="container space-1">
    <div class="rounded overflow-hidden space-top-2 space-top-lg-0 px-3 px-md-8 pr-lg-0 shadow" style="background-color:#EAF4FD">
      <div class="row justify-content-lg-between align-items-lg-center no-gutters">
        <div class="col-lg-6 space-lg-1 text-center text-lg-left">
          <h2 class="mb-3">How to Make Money with Open API Business?</h2>
          <p class="mb-4">An API business model is more than just making money. Your startup can enable developers to create new business models and revenue streams, open up access to your systems in ways you couldn't achieve before, attract attention, gain market insights and even drive sales.</p>
          <a id="ga-OW02-05-03" href="https://blog.openapihub.com/en-us/how-to-make-money-with-open-api-business/?_gl=1*14wd72s*_gcl_au*OTAyNDU1OTUxLjE2OTQ0MDAwNDA." class="btn btn-primary transition-3d-hover" target="_blank">Learn More about API Business Model</a>
        </div>
        <div class="col-lg-6 space-top-1 ml-auto">
          <div class="w-80 mx-auto">
            <img class="img-fluid" src="@/assets/svg/illustrations/monetization/monetization-hero.svg">
          </div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: 'CTA'
}
</script>

<style lang="scss" scoped>
</style>
